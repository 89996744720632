import React, { useState, useEffect } from 'react';
import MaterialTable from 'material-table';
import { useSelector } from "react-redux";
import CircularLoading from "../components/CircularLoading";
import { useTranslation } from "react-i18next";
import { colors } from '../components/Theme/WebTheme';
import {
  Select,
  MenuItem,
  FormControl,
} from '@mui/material';

export default function GovEarningReport() {
  const { t, i18n } = useTranslation();
  const isRTL = i18n.dir();
  const countrylistdata = useSelector(state => state.countrylistdata);
  const settings = useSelector(state => state.settingsdata.settings);
  const [govObj, setGovObj] = useState();
  const [data, setData] = useState([]);
  const earningreportsdata = useSelector(state => state.earningreportsdata);
  const [selectedCountry, setSelectedCountry] = useState(t('select_country'));
  const [countries, setCountries] = useState([]);

  const [selectedGov, setSelectedGov] = useState(t('select_gov'));

  useEffect(() => {
    if (settings && settings.govlist) {
      let gv = settings.govlist;
      let govObj = {};
      for (let i = 0; i < gv.length; i++) {
        govObj[gv[i].value] = gv[i].label;
      }
      setGovObj(govObj);
    }
  }, [settings]);

  const columns = [
    { title: t('year'), field: 'year' },
    { title: t('months'), field: 'monthsName' },
    { title: t('country'), field: 'country', },
    { title: t('govName'), field: 'govId', render: rowData => govObj && govObj[rowData.govId] },
    { title: t('booking_count'), field: 'total_rides' },
    { title: t('Gross_trip_cost'), render: rowData => (parseFloat(rowData.tripCost) + parseFloat(rowData.cancellationFee)).toFixed(settings.decimal), editable: 'never' },
    { title: t('trip_cost_driver_share'), field: 'driverShare' },
    { title: t('fleet_admin_fee'), field: 'fleetadminFee' },
    { title: t('cancellationFee'), field: 'cancellationFee' },
    { title: t('convenience_fee'), field: 'convenienceFee' },
    { title: t('Discounts'), field: 'discountAmount' },
    { title: t('Profit'), render: rowData => (parseFloat(rowData.convenienceFee) + parseFloat(rowData.cancellationFee) - parseFloat(rowData.discountAmount)).toFixed(settings.decimal), editable: 'never' },
  ];

  useEffect(() => {
    if (earningreportsdata.GovEarningsReport) {
      setData(earningreportsdata.GovEarningsReport);
    }
  }, [earningreportsdata.GovEarningsReport]);

  useEffect(() => {
    if (countrylistdata.countries) {
      setCountries(countrylistdata.countries);
    } else {
      setCountries([]);
    }
  }, [countrylistdata.countries]);

  const handleCountrySelect = (event) => {
    let arr = [];
    if (earningreportsdata.GovEarningsReport) {
      for (let i = 0; i < earningreportsdata.GovEarningsReport.length; i++) {
        if (earningreportsdata.GovEarningsReport[i].country === event.target.value&& ((selectedGov && earningreportsdata.GovEarningsReport[i].govId ===  selectedGov)|| !selectedGov )) {
          arr.push(earningreportsdata.GovEarningsReport[i])
        }
      }
    }
    setData(arr);
    setSelectedCountry(event.target.value);
  }

  const handleGovSelection = (event) => {
    let arr = [];
    if (earningreportsdata.GovEarningsReport) {
      for (let i = 0; i < earningreportsdata.GovEarningsReport.length; i++) {
        if (earningreportsdata.GovEarningsReport[i].govId === event.target.value && ((selectedCountry && earningreportsdata.GovEarningsReport[i].country ===  selectedCountry)|| !selectedCountry )) {
          arr.push(earningreportsdata.GovEarningsReport[i])
        }
      }
    }
    setData(arr);
    setSelectedGov(event.target.value);
  }


  const [selectedRow, setSelectedRow] = useState(null);

  return (
    earningreportsdata.loading ? <CircularLoading /> :
      <div>
        <FormControl sx={{ m: 1, width: 300 }}>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={selectedCountry}
            onChange={handleCountrySelect}
            style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left', border: '1px solid #9E9E9E' }}
          >
            <MenuItem disabled value={t('select_country')} style={{ direction: isRTL === 'rtl' ? 'rtl' : 'ltr', width: '100%', justifyContent: 'flex-start', paddingLeft: 10 }}>
              {t('select_country')}
            </MenuItem>
            {countries.map((item) =>
              <MenuItem dense={true} key={item.country} value={item.country_code} style={{ direction: isRTL === 'rtl' ? 'rtl' : 'ltr', width: '100%', justifyContent: 'flex-start', paddingLeft: 10 }}>
                {t(item.country)}</MenuItem>)}</Select>
        </FormControl>
        <FormControl sx={{ m: 1, width: 300 }}>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={selectedGov}
            onChange={handleGovSelection}
            style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left', border: '1px solid #9E9E9E' }}
          >
            <MenuItem disabled value={t('select_gov')} style={{ direction: isRTL === 'rtl' ? 'rtl' : 'ltr', width: '100%', justifyContent: 'flex-start', paddingLeft: 10 }}>
              {t('select_gov')}
            </MenuItem>
            {settings && settings.govlist && settings.govlist.map((item) =>
              <MenuItem dense={true} key={item.label} value={item.value} style={{ direction: isRTL === 'rtl' ? 'rtl' : 'ltr', width: '100%', justifyContent: 'flex-start', paddingLeft: 10 }}>
                {t(item.label)}</MenuItem>)}</Select>
        </FormControl>
        <MaterialTable
          title={t('earning_reports')}
          columns={columns}
          style={{ direction: isRTL === 'rtl' ? 'rtl' : 'ltr', borderRadius: "8px", boxShadow: "4px 4px 6px #9E9E9E" }}
          data={data}
          onRowClick={((evt, selectedRow) => setSelectedRow(selectedRow.tableData.id))}
          options={{
            exportButton: true,
            rowStyle: rowData => ({
              backgroundColor: (selectedRow === rowData.tableData.id) ? colors.THIRDCOLOR : colors.WHITE
            }),
            editable: {
              backgroundColor: colors.WHITE,
              fontSize: "0.8em",
              fontWeight: 'bold ',
              fontFamily: 'Lucida Console", "Courier New", monospace'
            },
            cellStyle: { textAlign: 'center' },
            headerStyle: {
              position: "sticky",
              textAlign: 'center',
              top: "0px",
              backgroundColor: colors.SECONDARY,
              color: colors.WHITE,
              fontSize: "0.8em",
              fontWeight: 'bold ',
              fontFamily: 'Lucida Console", "Courier New", monospace'
            },
          }}
          localization={{
            toolbar: {
              searchPlaceholder: (t('search')),
              exportTitle: (t('export')),
            },
            pagination: {
              labelDisplayedRows: ('{from}-{to} ' + (t('of')) + ' {count}'),
              firstTooltip: (t('first_page_tooltip')),
              previousTooltip: (t('previous_page_tooltip')),
              nextTooltip: (t('next_page_tooltip')),
              lastTooltip: (t('last_page_tooltip'))
            },
          }}

        />
      </div>
  );
}
