import {
    FETCH_HOMESLIDER,
    FETCH_HOMESLIDER_SUCCESS,
    FETCH_HOMESLIDER_FAILED,
    EDIT_HOMESLIDER
  } from "../store/types";
  
  const INITIAL_STATE = {
    homeslider: null,
    loading: false,
    error: {
      flag: false,
      msg: null
    }
  }
  
  export const homesliderreducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case FETCH_HOMESLIDER:
        return {
          ...state,
          loading: true
        };
      case FETCH_HOMESLIDER_SUCCESS:
        return {
          ...state,
          homeslider: action.payload,
          loading: false
        };
      case FETCH_HOMESLIDER_FAILED:
        return {
          ...state,
          homeslider: null,
          loading: false,
          error: {
            flag: true,
            msg: action.payload
          }
        };
      case EDIT_HOMESLIDER:
        return state;
      default:
        return state;
    }
  };