import {
    FETCH_HOMESLIDER,
    FETCH_HOMESLIDER_SUCCESS,
    FETCH_HOMESLIDER_FAILED,
    EDIT_HOMESLIDER
  } from "../store/types";
  import store from '../store/store';
  import { firebase } from '../config/configureFirebase';
  import { onValue, update, remove, push } from "firebase/database";
  import { uploadBytesResumable, getDownloadURL } from "firebase/storage";
  
  export const fetchHomeSlider = () => (dispatch) => {
    const {
        homesliderRef
    } = firebase;
    dispatch({
      type: FETCH_HOMESLIDER,
      payload: null
    });
    onValue(homesliderRef, snapshot => {
      if (snapshot.val()) {
        let data = snapshot.val();
        const arr = Object.keys(data).map(i => {
          data[i].id = i;
          return data[i]
        });
        dispatch({
          type: FETCH_HOMESLIDER_SUCCESS,
          payload: arr
        });
      } else {
        dispatch({
          type: FETCH_HOMESLIDER_FAILED,
          payload: store.getState().languagedata.defaultLanguage.no_cars
        });
      }
    });
  };
  
  export const editHomeslider = (slider, method) => async (dispatch) => {
    const {
    homesliderRef, 
    homesliderEditRef,
    homesliderImage
    } = firebase;
    dispatch({
      type: EDIT_HOMESLIDER,
      payload: { method, slider }
    });
    if (method === 'Add') {
      push(homesliderRef, slider);
    } else if (method === 'Delete') {
      remove(homesliderEditRef(slider.id));
    } else if (method === 'UpdateImage') {
      await uploadBytesResumable(homesliderImage(slider.id),slider.slider_image);
      let image = await getDownloadURL(homesliderImage(slider.id));
      let data = slider;
      data.slider_image = image;
      update(homesliderEditRef(slider.id), data);
    }
    else {
      update(homesliderEditRef(slider.id),slider);
    }
  }