export const FirebaseConfig = {
	"projectId": "almishwarnet-1c488",
	"appId": "1:897843792062:web:5be5af371335458ef336ef",
	"databaseURL": "https://almishwarnet-1c488-default-rtdb.europe-west1.firebasedatabase.app",
	"storageBucket": "almishwarnet-1c488.appspot.com",
	"locationId": "europe-central2",
	"apiKey": "AIzaSyDbtfVA7_9UTnxru-t-Ptj2EvtO9HisYC0",
	"authDomain": "almishwarnet-1c488.firebaseapp.com",
	"messagingSenderId": "897843792062",
	"measurementId": "G-W4V43F3P9T"
};