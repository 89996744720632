import React, { useState, useEffect, useRef } from 'react';
import MaterialTable from 'material-table';
import { useSelector, useDispatch } from "react-redux";
import CircularLoading from "../components/CircularLoading";
import { api } from 'common';
import { useTranslation } from "react-i18next";
import {colors} from '../components/Theme/WebTheme';
import {
    Modal,
    Grid,
    Typography
  } from '@mui/material';
import Button from "components/CustomButtons/Button.js";
import CancelIcon from '@mui/icons-material/Cancel';
import AlertDialog from '../components/AlertDialog';
import CircularProgress from '@mui/material/CircularProgress';
import Tooltip from '@mui/material/Tooltip';
import { makeStyles } from '@mui/styles';


const useStyles = makeStyles((theme) => ({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    submit3:{
      width:'100%',
      borderRadius:3,
      marginTop:2,
      padding:4
    },
    paper: {
      width: 500,
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      borderRadius:15,
      alignItems: 'center',
      justifyContent: 'center',
    },
  }));

export default function HomeSlider() {
  const { t,i18n } = useTranslation();
  const isRTL = i18n.dir();
  const {
    editHomeslider
  } = api;

 

  const columns = [
    { title: t('title'), field: 'title'},
    { title: t('image'),  field: 'slider_image',
        initialEditValue: 'https://cdn.pixabay.com/photo/2012/04/15/22/09/car-35502__480.png',
        render: rowData => rowData.slider_image? <button onClick={()=>{onClick(rowData)}}><img alt='slider_image' src={rowData.slider_image} style={{width: 50}}/></button>:null
    },
    { title: t('description'), field: 'description' },
    {
      title: t('levels'),
      field: 'levels',
      lookup: { level1 : t('level_1'), level2: t('level_2'), level3: t('level_3')},
      
    },
  ];
  const settings = useSelector(state => state.settingsdata.settings);
  const [data, setData] = useState([]);
  const homesliderdata = useSelector(state => state.homesliderdata);
  const dispatch = useDispatch();
  const classes = useStyles();
  const [userData, setUserData] = useState();
  const [profileModal, setProfileModal] =  useState(false);
  const [imageData, setImageData] =  useState(false);
  const [commonAlert, setCommonAlert] = useState({ open: false, msg: '' });
  const [loading, setLoading] = useState(false);
  const rootRef = useRef(null);
  const [selectedRow, setSelectedRow] = useState(null);


  useEffect(() => {
    if (homesliderdata.homeslider) {
      setData(homesliderdata.homeslider);
    }else{
      setData([]);
    }
  }, [homesliderdata.homeslider]);

  const [selectedImage, setSelectedImage] = useState(null); 
  const handleProfileModal = (e) => {
    setProfileModal(false);
    setSelectedImage(null);
  }


  const handleCommonAlertClose = (e) => {
    e.preventDefault();
    setCommonAlert({ open: false, msg: '' })
  };

  const handleSetProfileModal = (e) =>{
    e.preventDefault();
    if(selectedImage){
      setLoading(true);
      let finalData = userData;
      finalData.slider_image = selectedImage;
      dispatch(editHomeslider(finalData, "UpdateImage"));
      setProfileModal(false); 
      setTimeout(()=>{
        setSelectedImage(null);
        setLoading(false); 
      },10000);
    }
    else{
      setCommonAlert({ open: true, msg: t('choose_image_first') })
    }
  }

  const onClick = (rowData) => {
    setImageData(rowData.slider_image);
    setProfileModal(true);
    setUserData(rowData);
  };
  

  return (
    homesliderdata.loading ? <CircularLoading /> :
    <div>
      <MaterialTable
        title={t('home_slider')}
        columns={columns}
        style={{direction:isRTL ==='rtl'?'rtl':'ltr', borderRadius: "8px", boxShadow: "4px 4px 6px #9E9E9E"}}
        data={data}
        onRowClick={((evt, selectedRow) => setSelectedRow(selectedRow.tableData.id))}
        options={{
          exportButton: true,
          pageSize: 10,
          rowStyle: rowData => ({
            backgroundColor: (selectedRow === rowData.tableData.id) ?  colors.THIRDCOLOR: colors.WHITE
          }),
          editable:{
            backgroundColor: colors.WHITE,
            fontSize: "0.8em",
            fontWeight: 'bold ',
            fontFamily: 'Lucida Console", "Courier New", monospace'
          },
          cellStyle:{ textAlign: 'center'},
          headerStyle: {
            position: "sticky",
            textAlign:'center',
            top: "0px",
            backgroundColor: colors.SECONDARY ,
            color:colors.WHITE,
            fontSize: "0.8em",
            fontWeight: 'bold ',
            fontFamily: 'Lucida Console", "Courier New", monospace'
          },
        }}
        localization={{body:{
          addTooltip: (t('add')),
          deleteTooltip: (t('delete')),
          editTooltip: (t('edit')),
          emptyDataSourceMessage: (
            (t('blank_message'))
        ),
        editRow: { 
          deleteText: (t('delete_message')),
          cancelTooltip: (t('cancel')),
          saveTooltip: (t('save')) 
            }, 
          },
          toolbar: {
            searchPlaceholder: (t('search')),
            exportTitle: (t('export')),
          },
          pagination: {
            labelDisplayedRows: ('{from}-{to} '+ (t('of'))+ ' {count}'),
            firstTooltip: (t('first_page_tooltip')),
            previousTooltip: (t('previous_page_tooltip')),
            nextTooltip: (t('next_page_tooltip')),
            lastTooltip: (t('last_page_tooltip'))
          },
        }}
        editable={settings.AllowCriticalEditsAdmin ? {
          onRowAdd: newData =>
          settings.AllowCriticalEditsAdmin?
            new Promise((resolve,reject) => {
              setTimeout(() => {
                if(newData && newData.title){
                    newData['createdAt'] = new Date().getTime();
                    dispatch(editHomeslider(newData,"Add"));
                    resolve();
                } else {
                  alert(t('proper_input_title'));
                  reject();
                }
              }, 600);
            })
            :
            new Promise(resolve => {
              setTimeout(() => {
                resolve();
                alert(t('demo_mode'));
              }, 600);
            }),
            onRowDelete: oldData =>
            settings.AllowCriticalEditsAdmin?
            new Promise(resolve => {
              setTimeout(() => {
                resolve();
                dispatch(editHomeslider(oldData,"Delete"));
              }, 600);
            })
            :
            new Promise(resolve => {
              setTimeout(() => {
                resolve();
                alert(t('demo_mode'));
              }, 600);
            }),
          onRowUpdate: (newData, oldData) =>
            new Promise((resolve, reject)=> {
              setTimeout(() => {
                resolve();
                dispatch(editHomeslider(newData, "Update"));
              }, 600);
            })
        } : null}
      />
      <Modal
      disablePortal
      disableEnforceFocus
      disableAutoFocus
      open={profileModal}
      onClose={handleProfileModal}
      className={classes.modal}
      container={() => rootRef.current}
    >
    <Grid container spacing={1} className={classes.paper} style={{direction:isRTL==='rtl'?'rtl':'ltr'}}>
      <Grid item xs={12} sm={12} md={12} lg={12}>
          <Typography component="h1" variant="h6">
              {t('image')}
            <input
                type="file"
                style={{marginLeft:10}}
                name= {t('image')}
                onChange={(event) => {
                    setSelectedImage(event.target.files[0]);
                }}
            />
          </Typography>
       </Grid>
       <Grid item xs={12} sm={12} md={12} lg={12}>
                {selectedImage  && !loading ? 
                  <Tooltip title={t('cancel')}>
                    <CancelIcon onClick={()=>setSelectedImage(null)} style={{ fontSize: 30,backgroundColor:colors.RED,borderRadius:50,color:"white" }}  />
                  </Tooltip>
                : null }
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                {selectedImage ? 
                 <img alt="not fount" width={"200px"} height={"200px"}  src={URL.createObjectURL(selectedImage)} style={{marginTop:15,marginBottom:20}}/>
                 :
                  <img alt="not fount" width={"200px"} height={"200px"}  src={imageData} style={{marginTop:10}}/>
                }
              <br />
              </Grid>

        <Grid item xs={12} sm={12} md={12} lg={12} style={{textAlign:isRTL==='rtl'?'right':'left'}}>
        {loading ? 
          <Grid
            container
            spacing={0}
            alignItems="center"
            justify="center"
            style={{ minHeight: '5vh' }} >
            <CircularProgress/>
          </Grid>
          :
          <Grid item xs={12} sm={12} md={12} lg={12} style={{textAlign:isRTL==='rtl'?'right':'left'}}>
          <Button onClick={handleProfileModal} variant="contained" color="danger">
            {t('cancel')}
          </Button>
          <Button onClick={handleSetProfileModal} variant="contained" color="success" style={{marginLeft:10}}>
            {t('save')}
          </Button>
          </Grid>
        }
        </Grid>
      </Grid>
    </Modal>
    <AlertDialog open={commonAlert.open} onClose={handleCommonAlertClose}>{commonAlert.msg}</AlertDialog>
    </div>
  );
}
