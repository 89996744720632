export const FareCalculator = (distance,time,rateDetails,instructionData, decimal, zones, polypoints)=>{  

    function checkIntersection(point, vs) {
        var x = point[0], y = point[1];
        var inside = false;
        for (var i = 0, j = vs.length - 1; i < vs.length; j = i++) {
            var xi = vs[i][0], yi = vs[i][1];
            var xj = vs[j][0], yj = vs[j][1];
            
            var intersect = ((yi > y) !== (yj > y))
                && (x < (xj - xi) * (y - yi) / (yj - yi) + xi);
            if (intersect) inside = !inside;
        }
        return inside;
    }

    let baseCalculateds = 0;
    const initial_units = parseFloat(rateDetails.initial_units)>0 ? parseFloat(rateDetails.initial_units) : 0;
    const intial_rate = parseFloat(rateDetails.intial_rate);
    const actual_distance = parseFloat(distance);
    const rate_per_unit_distance = parseFloat(rateDetails.rate_per_unit_distance);

    if(initial_units>0){
        baseCalculateds = intial_rate;
    }
    
    let count = false;
    let rate_per_km = 0;

    if(rateDetails && rateDetails.rate_per_km && rateDetails.rate_per_km.length > 0){
        for(let i=0; i<rateDetails.rate_per_km.length;i++){
            if(actual_distance > rateDetails.rate_per_km[i].from && actual_distance <= rateDetails.rate_per_km[i].to){
                count = true;
                rate_per_km = rateDetails.rate_per_km[i].amount;
                break;
            }
        }
    }

    let lastrateperkm = (rateDetails.rate_per_km[(rateDetails.rate_per_km.length) - 1].amount);

    console.log(count)

    if(count){
        baseCalculateds = actual_distance * rate_per_km ;
    }else{
        baseCalculateds = actual_distance * lastrateperkm ;
    }

    //baseCalculated =  baseCalculated + (actual_distance > initial_units? (rate_per_unit_distance * (actual_distance - initial_units)) : 0);

    baseCalculateds =  baseCalculateds + (parseFloat(rateDetails.rate_per_hour) * (parseFloat(time) / 3600));

    let baseCalculated = parseFloat(baseCalculateds);

    if(rateDetails.base_fare>0){
        baseCalculated = baseCalculated + rateDetails.base_fare;
    }
    if(instructionData && instructionData.parcelTypeSelected){
        baseCalculated = baseCalculated + instructionData.parcelTypeSelected.amount;
    }
    if(instructionData && instructionData.optionSelected){
        baseCalculated = baseCalculated + instructionData.optionSelected.amount;
    }

    let total = baseCalculated > parseFloat(rateDetails.min_fare) ? baseCalculated : parseFloat(rateDetails.min_fare);

    console.log(total)

    if(rateDetails.peak_hours && rateDetails.peak_hours.length>0){
        for(let i=0; i<rateDetails.peak_hours.length;i++){
            let peakRow = rateDetails.peak_hours[i];
            const peak_start_time = (new Date(peakRow.start_time).getHours() * 60) + new Date(peakRow.start_time).getMinutes();
            const peak_end_time = (new Date(peakRow.end_time).getHours() * 60) + new Date(peakRow.end_time).getMinutes();
            const cur_time = (new Date().getHours() * 60) + new Date().getMinutes();
            if(cur_time >= peak_start_time && cur_time< peak_end_time){
                total = total + (total * parseFloat(peakRow.price_surge)/100);
            }
        }  
    }

    if(zones && zones.length > 0){
        for(let i=0; i < zones.length; i++){
            if(zones[i].zone_path && zones[i].active){
                for(let j=0; j<polypoints.length;j++){
                    if(checkIntersection(polypoints[j], zones[i].zone_path)){
                        total = total + zones[i].zone_surge;
                        break;
                    }
                }
            }
        }
    }

    let convenienceFee = 0;
    if(rateDetails.convenience_fee_type && rateDetails.convenience_fee_type == 'flat'){
        convenienceFee = rateDetails.convenience_fees;
    }else{
        convenienceFee = (total*parseFloat(rateDetails.convenience_fees)/100);
    }
    let grand = total + convenienceFee;

    console.log(grand)

    let quotient = grand / 250 ;
    let reminder = grand % 250 ;

    console.log(quotient, reminder)

    let lastamounnt = 0;

    if (reminder>0){
        lastamounnt = ( quotient.toFixed(0) * 250) + 250 ;
    }else{
        lastamounnt = grand.toFixed(0);
    }

    console.log(lastamounnt)

    let lastConvenienceFee = 0;
    if(rateDetails.convenience_fee_type && rateDetails.convenience_fee_type == 'flat'){
        lastConvenienceFee = rateDetails.convenience_fees;
    }else{
        lastConvenienceFee = (lastamounnt*parseFloat(rateDetails.convenience_fees)/100);
    }

    let lastTotal = lastamounnt - lastConvenienceFee;

    return {
        totalCost:parseFloat(lastTotal),
        grandTotal:parseFloat(lastamounnt),
        convenience_fees:parseFloat(lastConvenienceFee)
    }  
}