import React,{ useState, useEffect } from 'react';
import { downloadCsv } from '../common/sharedFunctions';
import MaterialTable from "material-table";
import CircularLoading from "../components/CircularLoading";
import { useSelector, useDispatch } from "react-redux";
import { api } from 'common';
import { useTranslation } from "react-i18next";
import moment from 'moment/min/moment-with-locales';
import {colors} from '../components/Theme/WebTheme';
import {
  Grid,
  Typography,
  Button
} from '@mui/material';
import {FirebaseConfig} from '../config/FirebaseConfig';
import AlertDialog from '../components/AlertDialog';

const Withdraws = () => {
  const { t,i18n } = useTranslation();
  const isRTL = i18n.dir();
  const settings = useSelector(state => state.settingsdata.settings);
  const providers = useSelector(state => state.paymentmethods.providers);
  const {
    completeWithdraw
  } = api;
  const dispatch = useDispatch();

  const [payFound, setPayFound] = useState([]);
  useEffect(() =>{
    if(providers && providers.length > 0 ){
        for(let i=0; i<providers.length; i++){
            if(providers[i].withdraw_enable){
              setPayFound(providers[i]);
            }
        }
    }
  }, [providers])

  const columns =  [
    { title: t('processed'), field: 'processed', type: 'boolean',editable: 'never', cellStyle:{paddingLeft:isRTL=== 'rtl'?40:null}},  
    { title: t('requestDate'), field: 'date', render: rowData => rowData.date ? moment(rowData.date).format('lll') : null,cellStyle:{textAlign:'center'},headerStyle:{textAlign:'center'},
              exportTransformer: (rowData) => new Date(rowData.date).toLocaleDateString() + ' '+ new Date(rowData.date).toLocaleTimeString()},
    { title: t('driver_name'),field: 'name',editable: 'never', cellStyle:{textAlign:'center'},headerStyle:{textAlign:'center'}},
    { title: t('amount'), field: 'amount',editable: 'never',cellStyle:{textAlign:'center'},headerStyle:{textAlign:'center'}},
    { title: t('currency_code'), field: 'currency',editable: 'never',cellStyle:{textAlign:'center'},headerStyle:{textAlign:'center'}},
    { title: t('processDate'), field: 'processDate', render: rowData => rowData.processDate ? moment(rowData.processDate).format('lll') : null,cellStyle:{textAlign:'center'},headerStyle:{textAlign:'center'} },
    { title: t('error'), field: 'error' ,editable: 'never'},
    { title: t('stripe_account_id'), field: 'stripe_account_id',editable: 'never', hidden: payFound && payFound.id === 'stripe' ? false : true },
    { title: t('paypal_email'), field: 'paypal_email' ,editable: 'never', hidden: payFound && payFound.id === 'paypal' ? false : true },
  ];
  const [data, setData] = useState([]);
  const withdrawdata = useSelector(state => state.withdrawdata);

  useEffect(()=>{
        if(withdrawdata.withdraws){
            setData(withdrawdata.withdraws);
        }else{
          setData([]);
        }
  },[withdrawdata.withdraws]);

  const [selectedRow, setSelectedRow] = useState(null);
  const [commonAlert, setCommonAlert] = useState({ open: false, msg: '' });
  
  const cancelOperation = () => {
    setCommonAlert({ open: false, msg: '' })
  };
 
  const doWithdraw = async () => {
    if(payFound && payFound.public_key.length > 0){
      const batchArr = [];
      for(let i=0; i<data.length; i++){
        if(payFound && payFound.id === "stripe"){
          if(data[i].hasOwnProperty('stripe_account_id') && !data.processed){
            const body = {
              amount: data[i].amount,
              currency: data[i].currency,
              stripe_account_id: data[i].stripe_account_id
            }
            try{
              const response = await fetch(`https://${FirebaseConfig.projectId}.web.app/stripe-transfer`, {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json'
                },
                body: JSON.stringify(body)
              })
              const json = await response.json();
              if(json.error){
                dispatch(completeWithdraw({
                  id: data[i].id,
                  error: json.error
                }, false));
              } else{
                dispatch(completeWithdraw({
                  ...data[i], transfer_id : json.transfer.id, error: ""
                }, true));
              }
            }
            catch(err) {
              dispatch(completeWithdraw({
                id: data[i].id,
                error: err.toString()
              }, false));
            };
          }
        }else{
          if(data[i].hasOwnProperty('paypal_email') && !data.processed){
            batchArr.push({
                "amount": {
                  "value": data[i].amount,
                  "currency": data[i].currency
                },
                "sender_item_id": data[i].id,
                "recipient_wallet": "PAYPAL",
                "receiver": data[i].paypal_email
            })
          }
        }
      }
      if(batchArr.length> 0){
        const payoutData = {data:{
          "sender_batch_header": {
            "sender_batch_id": "Payouts_" + new Date().getTime(),
            "email_subject": "You have a payout!",
            "email_message": "You have received a payout! Thanks for using our service!",
            "recipient_type": "EMAIL"
          },
          "items" :batchArr
        }}
        const response = await fetch(`https://${FirebaseConfig.projectId}.web.app/paypal-transfer`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(payoutData)
        })
        const json = await response.json();
        if(json.success){
          for(let i=0;i<batchArr.length;i++){
            let item = batchArr[i];
            dispatch(completeWithdraw({
              id: item.sender_item_id, payout_batch_id : json.payout_details.batch_header.payout_batch_id, error: ""
            }, true));
          }
        }else{
          for(let i=0;i<batchArr.length;i++){
            let item = batchArr[i];
            dispatch(completeWithdraw({
              id: item.sender_item_id, error : json.error
            }, false));
          }
        }
      }
      alert(t('success'));
    }else{
      setCommonAlert({ open: true, msg: t('provider_not_found')})
    }
  }

  
  return (
    withdrawdata.loading? <CircularLoading/>:
    <div>
      <div  style={{borderRadius: "19px", padding: 10}}>
        <Typography variant="h4" style={{margin:"20px 20px 20px 15px",textAlign:isRTL==='rtl'?'right':'left'}}>{t('withdraw')}</Typography>
        <Grid container direction="row" spacing={2}>
          {payFound && payFound.id ?
            <Grid item xs style={{textAlign:isRTL==='rtl'?'right':'left', display:'flex',  flexDirection:'row'}}>
                <Button onClick={() => doWithdraw()} variant="contained" style={{width: 230, backgroundColor:'#088395', color:"white", borderRadius: "5px", boxShadow: "4px 4px 6px #9E9E9E"}}  size='large'>{payFound ? payFound.id : null } {t('withdraw')}</Button>
            </Grid>
          : null }
        </Grid>
      </div>

    <MaterialTable
      title={t('Withdraw')}
      columns={columns}
      style={{direction:isRTL ==='rtl'?'rtl':'ltr', borderRadius: "8px", boxShadow: "4px 4px 6px #9E9E9E"}}
      data={data}
      onRowClick={((evt, selectedRow) => setSelectedRow(selectedRow.tableData.id))}
      options={{
        exportCsv: (columns, data) => {
          let hArray = [];
          const headerRow = columns.map(col => {
            if (typeof col.title === 'object') {
              return col.title.props.text;
            }
            hArray.push(col.field);
            return col.title;
          });
          const dataRows = data.map(({ tableData, ...row }) => {
            row.date = new Date(row.date).toLocaleDateString() + ' '+ new Date(row.date).toLocaleTimeString()
            row.processDate = row.processDate? new Date(row.processDate).toLocaleDateString() + ' '+ new Date(row.processDate).toLocaleTimeString(): ''
            let dArr = [];
            for(let i=0;i< hArray.length; i++) {
              dArr.push(row[hArray[i]]);
            }
            return Object.values(dArr);
          })
          const { exportDelimiter } = ",";
          const delimiter = exportDelimiter ? exportDelimiter : ",";
          const csvContent = [headerRow, ...dataRows].map(e => e.join(delimiter)).join("\n");
          const csvFileName = 'withdraw.csv';
          downloadCsv(csvContent, csvFileName);
        },
        exportButton: {
          csv: settings.AllowCriticalEditsAdmin,
          pdf: false,
        },
        rowStyle: rowData => ({
          backgroundColor: (selectedRow === rowData.tableData.id) ?  colors.THIRDCOLOR: colors.WHITE
        }),
        editable:{
          backgroundColor: colors.WHITE,
          fontSize: "0.8em",
          fontWeight: 'bold ',
          fontFamily: 'Lucida Console", "Courier New", monospace'
        },
        headerStyle: {
          position: "sticky",
          top: "0px",
          backgroundColor: colors.SECONDARY ,
          color:  colors.WHITE,
          fontSize: "0.8em",
          fontWeight: 'bold ',
          fontFamily: 'Lucida Console", "Courier New", monospace'
        }
      }}
      localization={{
        toolbar: {
          searchPlaceholder: (t('search')),
          exportTitle: (t('export')),
        },
        header: {
          actions: (t('actions')) 
      },
      pagination: {
        labelDisplayedRows: ('{from}-{to} '+ (t('of'))+ ' {count}'),
        firstTooltip: (t('first_page_tooltip')),
        previousTooltip: (t('previous_page_tooltip')),
        nextTooltip: (t('next_page_tooltip')),
        lastTooltip: (t('last_page_tooltip'))
      },
      }}
    />
    <AlertDialog open={commonAlert.open} onClose={cancelOperation}>{commonAlert.msg}</AlertDialog>
    </div>
  );
}

export default Withdraws;
